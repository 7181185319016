
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.s-form-data {
  &__title {
    font-size: 16px;
    line-height: 24px;
  }
}
